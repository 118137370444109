import React from 'react';
import Nav from '../components/Nav';

export default function Snippets() {
  return (
    <>
      <Nav />
      Snippets
    </>
  );
}
